<template>
    <div style="height: inherit; position: relative; width: 100%;">
        <b-card>
            <div class="m-1">
                <h3 style="text-align: left">공지사항 {{(writeType === 'create')? '작성': '수정'}}</h3>
                <hr>
                제목
                <b-form-input v-model="title" placeholder="제목 입력"></b-form-input>
                <br>
                내용 
                <b-form-textarea 
                    v-model="contents" 
                    rows="3"
                    max-rows="20"
                    style="font-size: larger; font-weight: bold;"
                    placeholder="내용 입력"
                ></b-form-textarea>
                <br>
                <div v-if="writeType === 'create'" style="float: left;">
                    <b-form-checkbox switch v-model="sendAllInfoMsg">전체 고객 공지 알림</b-form-checkbox>
                </div>  
                <div style="float: right;" class="mb-1">
                    <b-button @click="goBack" class="mr-1">취소</b-button>
                    <b-button :variant="(writeType === 'create')?'success': 'warning'" @click="writeBoard">{{(writeType === 'create')? '완료': '수정'}}</b-button> 
                </div>
            </div>
        </b-card>
    </div>
</template>
<script>
import {
    BFormCheckbox,
    BButton,
    BFormTextarea,
    BFormInput,
    BCard,
    BTable,
    BModal,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import moment from 'moment'
import store from '@/store'


export default {
    components: {
        BFormCheckbox,
        BButton,
        BFormInput,
        BFormTextarea,
        BCard,
        BTable,
        BModal,
    },
    data() {
        return {
            title: '',
            contents: '',
            sendAllInfoMsg: false            
        }
    },
    computed: {
        dateFommater() {
            return moment().format('YYYY-MM-DD HH:mm:ss')
        },
        /* imgUrl() {
            return require(`@/assets/images/pages/1.png`)
        } */
    },
    props: {
        writeType: {
            type: String
        },
        writeData: {
            type: Object
        }
    },
    created() {
        if(this.writeType === 'modify') {
            let msg = this.writeData
            this.title = msg.title
            this.contents = msg.contents
        }
    },
    methods: {
        sendToastMsg(msgType, msgContents) {
            this.$toast(msgContents, {
                position: 'top-right',
                timeout: 1000,
                icon: true,
                closeOnClick: true,
                type: msgType,
                rtl: false
            })
        },
        async writeBoard() {
            let writerInfo = getUserData()
            if(writerInfo.role !== 'admin') {
                this.sendToastMsg('error', '관리자 권한이 없습니다.')
                return this.$router.push({ name: 'board-list', params: { } })
            }
            if(this.title.length > 0
                && this.contents.length > 0) {
                    if(this.writeType === 'create') {
                        await store.dispatch('board/createBoard', {
                            body: {
                                userInfo: {
                                    phone : writerInfo.phone,
                                    name : writerInfo.name,
                                    role : writerInfo.role
                                },
                                title: this.title,
                                contents: this.contents,
                                createdAt: this.dateFommater,
                                //updatedAt: '1',
                            }
                        })
                        if(this.sendAllInfoMsg) {
                            let data = {
                                title: '★ 공 지 사 항 ★',
                                description: '새로운 공지 알림이 있습니다.',
                                role: this.role,
                                target: 'customer'
                            }
                            console.log('Send Msg :: ', data)
                            //await store.dispatch('users/sendNoticeMsg', { queryBody: data })
                        }
                    } else {
                        await store.dispatch('board/modifyBoard', {id: this.writeData._id, 
                            body: {
                                userInfo: {
                                    phone : writerInfo.phone,
                                    name : writerInfo.name,
                                    role : writerInfo.role
                                },
                                title: this.title,
                                contents: this.contents,
                                //createdAt: this.writeData.createdAt,
                                updatedAt: this.dateFommater,
                            }
                        })
                    }
                    this.sendToastMsg('success', '완료!')
                    return this.$router.push({ name: 'board-list', params: { } })

            } else return this.sendToastMsg('warning', '제목 또는 내용이 입력되지 않았습니다.')
            
        },
        goBack() {
            this.sendToastMsg('warning', '공지사항 작성 취소')
            this.$router.push({ name: 'board-list', params: { } })
        },
        onRowSelected (items) {
            if(items.length > 0) {
                this.selected = items[0]
                this.$refs[`selectableTableModal`].show()
                this.$refs[`selectableTable`].clearSelected()
            }
        },
    },
}
</script>
<style scoped>

</style>